<template>
  <div>
    <!-- modal -->
    <b-modal ref="modal-info" hide-footer title="Détails de l'Achat">
      <p>
        Opération: <strong>{{ selectedOperation.type }}</strong>
      </p>
      <p>
        Designation: <strong>{{ selectedOperation.name }}</strong>
      </p>
      <p>
        Montant: <strong>{{ selectedOperation.amount }} $</strong>
      </p>
      <p>
        Payé par: <strong>{{ selectedOperation.payment_method }}</strong>
      </p>
      <hr>
      <p>
        Wallet: <strong>{{ selectedOperation.sku_user_destination }}</strong>
      </p> 

      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mt-3" variant="outline-primary" block
        @click="copyWallet(selectedOperation)">
        <feather-icon icon="CopyIcon" /> Copier wallet à récharger
      </b-button>
    </b-modal>


    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="4"
            class="d-none d-sm-inline align-items-center justify-content-start mb-1 mb-md-0 pull-left">
            <label>Afficher</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>Elements par page</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end pull-right">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Rechercher..." />
              <b-button variant="primary" :to="{ name: 'coin-add-new' }">
                <span class="text-nowrap">Acheter des cryptos</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="refDataListTable" class="position-relative" :items="fetchModuleRecharges" small responsive="sm"
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="Aucun resultat trouvé"
        :sort-desc.sync="isSortDirDesc">
        <!-- Column: id -->
        <template #cell(id)="data">
          <span>
            CM0{{ data.item.id }}
          </span>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${resolveDataStatusVariant(data.item.status)}`" class="text-capitalize">

            <span v-if="data.item.status == 0">
              <feather-icon icon="ReloadIcon" />
              En Attente
            </span>
            <span v-if="data.item.status == 1">
              <feather-icon icon="CheckIcon" />
              Confirmé
            </span>
            <span v-if="data.item.status == 4">
              <feather-icon icon="RemoveIcon" />
              Annulé
            </span>

          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- <feather-icon :id="`coin-row-${data.item.id}-preview-icon`" icon="EyeIcon" size="16" class="mx-1" @click="
              $router.push({
                name: 'coin-view',
                params: { id: data.item.id },
              })
              " />
            <b-tooltip title="Visionner Portefeuille" :target="`coin-row-${data.item.id}-preview-icon`" /> -->

            <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
              </template>
              <b-dropdown-item @click="showModal(data.item)">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Détails</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="userIsAdmin == true && data.item.status == 0 || data.item.status == null"
                @click="onConfirmQuestion(data.item)" @refetch-data="refetchData">
                <feather-icon icon="CheckIcon" />
                <span class="align-middle ml-50 text-success">Confirmer</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="userIsAdmin == true && data.item.status == 0 || data.item.status == null"
                @click="onRejectQuestion(data.item)" @refetch-data="refetchData" class="text-warning">
                <feather-icon icon="DeleteIcon" />
                <span class="align-middle ml-50  text-danger">Réjéter</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
              {{ dataMeta.of }} Enregistements</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalData" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
// import ListDataTable from "./ListDataTable.vue";
import ListDataTableFilters from "./ListDataTableFilters.vue";

// sweet alert
import Ripple from "vue-ripple-directive";
//
// alerts
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ListDataTableFilters,
    // ListDataTable,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      selectedOperation: {},
      userIsAdmin: JSON.parse(localStorage.getItem("userData")).role == "super-admin" ? true : false
    };
  },
  directives: {
    Ripple,
  },
  props: {},
  methods: {
    // notification
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
    showModal(coin) {
      this.selectedOperation = coin;
      this.$refs['modal-info'].show()
    },
    copyWallet(coin) {

      let text = coin.sku_user_destination;

      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });

      this.$refs['modal-info'].hide()

      this.showToast(
        "Copié dans la présse papier",
        text,
        "success",
        "InfoIcon",
        "top-center",
        5000
      );

    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
      }

      document.body.removeChild(textArea);
    },
    onRejectQuestion(coin) {
      this.$swal({
        title: "Action Irreversible",
        text: "Voulez-vous vraiment régéter ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Reget]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onReject(coin);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
    onConfirmQuestion(coin) {
      this.$swal({
        title: "Action Importante",
        text: "Voulez-vous vraiment confirmer ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Recharge]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onConfirm(coin);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-coin";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const onReject = (coin) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/rejectModule", coin.id)
        .then((response) => {
          // show confirmation notification            
          refetchData();

          alert(response.data.message)

        }).catch((error) => {

          console.log(error);

        });
    };
    const onConfirm = (coin) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/confirmModule", coin.id)
        .then((response) => {
          // show confirmation notification
          refetchData();

          alert(response.data.message)

        }).catch((error) => {

          console.log(error);


        });
    };

    const isAddNewDataSidebarActive = ref(false);

    const statusOptions = [
      { label: "En Activité", value: "active" },
      { label: "Inactif", value: "inactive" },
      { label: "Non-Spécifié", value: "pending" },
    ];

    const resolveDataStatusVariant = (status) => {
      if (status === "0") return "secondary";
      if (status === "1") return "success";
      if (status === "2") return "warning";
      return "secondary";
    };

    const {
      fetchModuleRecharges,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,

      // Extra Filters
      typeFilter,
      statusFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchModuleRecharges,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      onConfirm,
      onReject,

      // Filter
      avatarText,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
